import { BASE } from "@/api/index";
import axios from "@/api/http";

const BASE_URL = BASE.PRO1;

/**
 * 总控-供应商钱包流水
 * @param {*} params
 * @returns
 */
export function postSupplierWalletFlow(params = {}) {
  return axios.post(`${BASE_URL}/supplier/wallet/flow`, params);
}

/**
 * 总控-供应商钱包流水类型
 * @param {*} params
 * @returns
 */
export function postSupplierWalletFlowType(params = {}) {
  return axios.post(`${BASE_URL}/supplier/wallet/flow/type`, params);
}

/**
 * 总控-城市仓钱包流水
 * @param {*} params
 * @returns
 */
export function postLogisticsWalletFlow(params = {}) {
  return axios.post(`${BASE_URL}/logistics/wallet/flow`, params);
}

/**
 * 总控-城市仓钱包流水类型
 * @param {*} params
 * @returns
 */
export function postLogisticsWalletFlowType(params = {}) {
  return axios.post(`${BASE_URL}/logistics/wallet/flow/type`, params);
}
